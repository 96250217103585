export const keys = {
  auth: 'app.auth',
  locale: 'app.locale',
  preferredLayouts: 'grid.preferredLayouts',
  activeSearchIds: 'grid.activeSearchIds',
  searchColors: 'search.colors',
  searchShortNames: 'search.shortNames',
  offerListUserIdFilter: 'offer.offerListUserIdFilter',
  routingMapOptions: 'map.routingMapOptions',
  notificationRequirementsRead: 'notification.requirements.read',
  notificationSettingsDisabledSearches: 'notification.settings.disabledSearches',
};

export function get<T>(key: string, defaultValue: T): T {
  if (localStorage.getItem(key) === null) {
    return defaultValue;
  }

  return JSON.parse(localStorage.getItem(key) || '""');
}

export function set(key: string, value: unknown) {
  if (value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
