import { AbortOptions } from '@/shared/api/search/utils';

import axios from './axios';

interface ApiExchange {
  key: string;
  value: string;
}

export async function list(options?: AbortOptions): Promise<ApiExchange[]> {
  const { data } = await axios.get<ApiExchange[]>('enum/exchanges', {
    signal: options?.signal,
  });

  return data;
}
