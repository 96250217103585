import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getCurrentTheme, Theme } from '@/shared/theme';

export const useThemeStore = defineStore('theme', () => {
  const current = ref(getCurrentTheme());

  const isPrivate = computed(() => current.value !== Theme.B2PWeb);

  function switchTo(theme: Theme) {
    current.value = theme;
  }

  return {
    current,
    isPrivate,

    switchTo,
  };
});
