import { DateTime } from 'luxon';

import { AbortOptions, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';

export interface SearchOptions extends AbortOptions, PaginationOptions, SortingOptions {
  readonly ids?: string[];
  readonly name?: string;
  readonly siretNumber?: string;
  readonly vatNumber?: string;
  readonly country?: string;
  readonly zones?: string;
  readonly vehicleCertifications?: VehicleCertification[];
  readonly vehicleCharacteristics?: VehicleCharacteristic[];
  readonly vehicleTypes?: string[];
  readonly allowSubcontracting?: string;
  readonly allowLoading?: string;
}

export interface SearchResult {
  readonly id: string;
  readonly name: string;
  readonly country: string;
  readonly zipCode: string;
  readonly city: string;
  readonly logo: string | null;
  readonly directoryScore: number | null;
  readonly score: string | null;
  readonly forbiddings: {
    readonly loading: LoadingRestrictionType;
    readonly subcontracting: SubcontractingRestrictionType;
  };
}

export interface CustomerFile {
  readonly id: string;
  readonly customer: Customer;
  readonly certifications: VehicleCertification[];
  readonly axes: Axis[];
  readonly vehicleTypes: VehicleType[];
  readonly languages: string[];
  readonly activity: string | null;
  readonly directoryScore: number | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageCapacity: string | null;
  readonly distribution: string | null;
  readonly multimodalTransport: string | null;
  readonly isB2pSubcontractor: boolean;
  readonly isSubcontractor: boolean;
}

export interface MyCustomerFile {
  readonly customer: Customer;
  readonly certifications: VehicleCertification[];
  axes: MyAxis[];
  vehicleTypes: VehicleType[];
  readonly languages: string[];
  readonly activity: string | null;
  readonly directoryScore: number | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly documentOwner: {
    readonly name: string | null;
    readonly email: string | null;
    readonly phone: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageCapacity: string | null;
  readonly distribution: string | null;
  readonly multimodalTransport: string | null;
  readonly isB2pSubcontractor: boolean;
  readonly isSubcontractor: boolean;
}

export interface MyCustomerFileInput {
  readonly certifications: VehicleCertification[];
  readonly activity: string | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageArea: string | null;
  readonly distribution: string | null;
  readonly multimodalTransportation: string | null;
}

export interface Customer {
  readonly id: string;
  readonly name: string;
  readonly address1: string;
  readonly address2: string | null;
  readonly address3: string | null;
  readonly zipCode: string;
  readonly city: string | null;
  readonly country: string;
  readonly siret: string;
  readonly vatNumber: string | null;
  readonly napCode: string | null;
  readonly phone: string | null;
  readonly fax: string | null;
  readonly logo: string | null;
}

export interface Axis {
  readonly lading: AxisLocation;
  readonly delivery: AxisLocation;
}

export interface AxisInput {
  readonly lading: AxisLocation;
  readonly delivery: AxisLocation;
}

export interface MyAxis extends Axis {
  readonly id: string;
}

export interface AxisLocation {
  readonly country: string | null;
  readonly zones: string[];
}

export interface VehicleType {
  readonly id: string;
  readonly typeId: number | null;
  readonly quantity: number | null;
  readonly characteristics: VehicleCharacteristic[];
  readonly comments: string | null;
}

export interface VehicleTypeInput {
  readonly typeId: number | null;
  readonly quantity: number | null;
  readonly characteristics: VehicleCharacteristic[];
  readonly comments: string | null;
}

export enum LoadingRestrictionType {
  Allowed = 'allowed',
  Forbidden = 'forbidden',
  ForbiddenLocked = 'forbidden_locked',
  Warned = 'warned',
  WarnedLocked = 'warned_locked',
}

export enum SubcontractingRestrictionType {
  Allowed = 'allowed',
  Forbidden = 'forbidden',
  ForbiddenLocked = 'forbidden_locked',
}

export enum VehicleCertification {
  ADR = 1,
  ISO = 2,
  OEA = 3,
  Qualimat = 4,
}

export enum VehicleCharacteristic {
  Brackets = 'EQ',
  Crane = 'GR',
  FortyFourTons = '44',
  MovingBottom = 'FM',
  Palettisable = 'PA',
  SemiTrailer = 'SR',
  Straps = 'SA',
  Tailgate = 'HA',
  TrailerTruck = 'CR',
  TrolleyLoader = 'CE',
}

export interface Comment {
  readonly id: string;
  readonly user: {
    readonly id: string;
    readonly name: string;
  };
  readonly message: string;
  readonly creationDate: DateTime;
}

export interface Document {
  readonly type: {
    readonly id: number;
    readonly label: string;
    readonly isMandatory: boolean;
  };
  readonly status: {
    readonly label: string;
    readonly value: DocumentStatus;
    readonly isValid: boolean;
  };
  readonly recall: {
    readonly typeId: DocumentRecallType | null;
    readonly typeLabel: string | null;
    readonly date: DateTime | null;
  };
  readonly expirationDate: DateTime | null;
}

export interface MyDocument extends Document {
  readonly canBeSent: boolean;
}

export enum DocumentRecallType {
  Email = 1,
  Phone = 2,
}

export enum DocumentStatus {
  Valid = 1,
  AlmostExpired = 2,
  Expired = 3,
  NotConcerned = 4,
  Missing = 5,
}

export interface EnumItem<KeyType = string> {
  key: KeyType;
  value: string;
}
