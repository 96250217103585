import window from '@/shared/window';

export enum Theme {
  Astre = 'astre',
  B2PWeb = 'b2pweb',
  Evolutrans = 'evolutrans',
  FranceBenne = 'france-benne',
  FrancePlateaux = 'france-plateaux',
  Geodis = 'geodis',
  GroupementFlo = 'groupement-flo',
  Kuehne = 'kuehne',
  Stef = 'stef',
  TredUnion = 'tred-union',
}

const themesByDomain: Record<string, Theme> = {
  'app-astre.b2pweb.com': Theme.Astre,
  'app-evolutrans.b2pweb.com': Theme.Evolutrans,
  'app-france-benne.b2pweb.com': Theme.FranceBenne,
  'app-france-plateaux.b2pweb.com': Theme.FrancePlateaux,
  'app-geodis.b2pweb.com': Theme.Geodis,
  'app-flo.b2pweb.com': Theme.GroupementFlo,
  'app-kuehne.b2pweb.com': Theme.Kuehne,
  'app-kuehneroad.b2pweb.com': Theme.Kuehne,
  'app-stef.b2pweb.com': Theme.Stef,
  'app-tred-union.b2pweb.com': Theme.TredUnion,
};

export function getCurrentTheme(): Theme {
  return themesByDomain[window.document.domain] ?? Theme.B2PWeb;
}
