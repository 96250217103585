import { composeOffer } from '@/shared/api/offer/mappers';
import { Offer } from '@/shared/api/offer/types';
import {
  ApiOffer,
  ApiRealOffer,
  ApiRealOfferInput,
  OfferNotFoundError,
  OfferRefreshRestrictionDelayNotElapsedError,
} from '@/shared/api/offer/types-api';
import { AppError, OfferListFilters } from '@/shared/types';

import client, { isAxiosError } from './client';

interface RefreshOffersResponse {
  done: number;
  errors: { id: string; message: string; code: number }[];
}

export interface LocationInput {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
}

export interface LadingInput extends LocationInput {
  dateStart: string;
  dateEnd?: string;
}

export interface DeliveryInput extends LocationInput {
  dateStart?: string;
  dateEnd?: string;
}

export interface OfferInput {
  lading: LadingInput;
  delivery: DeliveryInput;
  goodType: string;
  truckTypes?: string[];
  hazardousMaterials: boolean;
  weight: number;
  length: number;
  volume?: number;
  notes?: string;
  orderRef?: string;
  price?: number;
}

export interface CreateOfferInput extends OfferInput {
  contact: {
    id: string;
    civility?: string;
    name?: string;
    email?: string;
    fax?: string;
    phone?: string;
    mobile?: string;
    languages?: string[];
  };
  differedAt?: string;
  profileId?: string;
  saveAsTemplate?: boolean;
}

export interface StatisticInput {
  price: number;
  isFull: boolean;
  carrier: {
    customerContact: {
      id: string | null;
      name: string;
      country: string | null;
    };
    contact: {
      id: string | null;
      name: string | null;
      fax: string | null;
      phone: string;
      email: string | null;
    };
    address: string | null;
    city: string | null;
    zipCode: string | null;
  };
}

export interface ValidationError {
  code: string;
  field: string;
  message: string;
}

export class FormValidationError extends Error {
  public errors: ValidationError[] = [];

  constructor(message: string, errors: ValidationError[]) {
    super(message);
    this.errors = errors;
  }
}

export async function createOffer(input: CreateOfferInput): Promise<Offer> {
  try {
    const { data } = await client.post<ApiOffer>('offers', input);
    return composeOffer(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while creating offer', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function updateOffer(id: string, input: OfferInput): Promise<Offer> {
  try {
    const { data } = await client.put<ApiOffer>(`offers/${id}`, input);
    return composeOffer(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while updating offer', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function refreshOffers(filters: OfferListFilters): Promise<RefreshOffersResponse> {
  const { data } = await client.post<RefreshOffersResponse>(`offers/refresh/bulk`, {
    ...filters,
  });
  return data;
}

export async function refreshOffer(id: string): Promise<void> {
  try {
    await client.post<void>(`offers/${id}/refresh`);
  } catch (e) {
    if (isAxiosError(e) && e.response) {
      const { status, data } = e.response;

      switch (status) {
        case 403:
          throw new OfferRefreshRestrictionDelayNotElapsedError(data.message);

        case 404:
          throw new OfferNotFoundError(data.message);
      }
    }

    throw e;
  }
}

export async function removeOffers(filters: OfferListFilters): Promise<number> {
  const { data } = await client.post<{ done: number }>('offers/delete/bulk', {
    ...filters,
  });
  return data.done;
}

export async function removeOffer(id: string, reason?: string): Promise<void> {
  await client.delete<void>(`offers/${id}`, {
    data: {
      deleteCause: reason,
    },
  });
}

export async function removeTemplate(id: string): Promise<void> {
  await client.delete<void>(`templateOffers/${id}`);
}

export async function updateStatistic(offerId: string, input: StatisticInput): Promise<void> {
  try {
    await client.post<void>(`realOffers/${offerId}/statistics`, input);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while saving statistic', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function createOrUpdateRealOffer(offerId: string, input: ApiRealOfferInput): Promise<ApiRealOffer> {
  try {
    const { data } = await client.post<ApiRealOffer>(`realOffers/${offerId}`, input);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while updating offer', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function sendRealOfferByEmail(id: string): Promise<void> {
  try {
    await client.post<void>(`realOffers/${id}/send`);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 422) {
      throw new AppError(e.response.data.message);
    }

    throw e;
  }
}
