import { Theme } from '@/shared/theme';

let values: Record<Theme, string>;

export function list() {
  return load();
}

export function label(key: Theme) {
  return load()[key];
}

function load() {
  if (!values) {
    values = {
      [Theme.Astre]: 'Astre',
      [Theme.B2PWeb]: 'B2PWeb',
      [Theme.Evolutrans]: 'Evolutrans',
      [Theme.FranceBenne]: 'France Benne',
      [Theme.FrancePlateaux]: 'France Plateaux',
      [Theme.Geodis]: 'Geodis',
      [Theme.GroupementFlo]: 'Groupement Flo',
      [Theme.Kuehne]: 'Kuehne',
      [Theme.Stef]: 'Stef',
      [Theme.TredUnion]: 'Tred Union',
    };
  }

  return values;
}
