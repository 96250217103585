import { onMounted, onUnmounted, ref } from 'vue';

export function useDevicePixelRatio() {
  const pixelRatio = ref(window.devicePixelRatio);
  let media: MediaQueryList;

  function cleanup() {
    media?.removeEventListener('change', observe);
  }

  function observe() {
    pixelRatio.value = window.devicePixelRatio;

    cleanup();

    media = window.matchMedia(`(resolution: ${pixelRatio.value}dppx)`);
    media.addEventListener('change', observe, { once: true });
  }

  onMounted(() => {
    observe();
  });

  onUnmounted(() => {
    cleanup();
  });

  return { pixelRatio };
}
