import window from '@/shared/window';

import { version } from '../../package.json';

declare global {
  interface Window {
    _env_: Record<string, string>;
  }
}

const env = window._env_ || {};

export default {
  version,
  env: env.VUE_APP_ENV ?? process.env.NODE_ENV,
  enabledBetaFeatures: env.VUE_APP_ENABLED_BETA_FEATURES || '',
  enableThemeSelector: !!env.VUE_APP_ENABLE_THEME_SELECTOR,
  autoCatchErrors: process.env.NODE_ENV === 'development',
  auth: {
    clientId: env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
    realm: env.VUE_APP_KEYCLOAK_REALM || '',
    url: env.VUE_APP_KEYCLOAK_URL || '',
    // Timeout in seconds before which the authentication server is declared unavailable
    authServerTimeout: 15,
    // Minimum duration in seconds during which the token must be valid before his expiration (default: 10)
    tokenValidityDuration: ~~(env.VUE_APP_KEYCLOAK_MINIMUM_TOKEN_VALIDITY_DURATION ?? 10),
    silentCheckRedirectUri: `${window.location.origin}${process.env.BASE_URL || '/'}silent-check-sso.html`,
  },
  eventStream: {
    subscriptionUrl: env.VUE_APP_EVENT_STREAM_SUBSCRIPTION_URL || '',
  },
  addTestSelectors: !!env.VUE_APP_ADD_TEST_SELECTORS,
  emails: {
    hotline: 'exploitation@b2pweb.com',
  },
  b2pOptim: {
    authUrl: env.VUE_APP_B2POPTIM_AUTH_URL || '',
  },
  eprotocol: {
    url: env.VUE_APP_EPROTOCOL_URL || '',
  },
  gedmouv: {
    url: env.VUE_APP_GEDMOUV_URL || '',
  },
  gedtrans: {
    phone: '+33484910093',
    emails: {
      contact: 'contact@gedtrans.com',
      hotline: 'hotline@gedtrans.com',
    },
    url: env.VUE_APP_GEDTRANS_URL || '',
  },
  palbank: {
    url: env.VUE_APP_PFM_CALCULATOR_URL || '',
  },
  map: {
    url: env.VUE_APP_MAP_URL || '',
  },
  notifications: {
    bufferCapacity: 20,
  },
  submission: {
    // Refresh interval in seconds of the submission status of an offer
    submissionStatusRefreshInterval: 15,
  },
  refreshRestrictionDelay: ~~(env.VUE_APP_REFRESH_RESTRICTION_DELAY ?? 900),
  tracking: {
    url: env.VUE_APP_TRACKING_URL || '',
    siteId: env.VUE_APP_TRACKING_SITE_ID || '',
    containerId: env.VUE_APP_TRACKING_CONTAINER_ID || '',
    webSiteAccess: env.VUE_APP_TRACKING_WEBSITE_ACCESS || false,
    webSiteAccessDomain: env.VUE_APP_TRACKING_WEBSITE_DOMAIN || '.b2pweb.com',
    webSiteAccessPath: env.VUE_APP_TRACKING_WEBSITE_PATH || '/',
  },
  urls: {
    appBaseUrl: process.env.BASE_URL || '/',
    help: env.VUE_APP_HELP_URL || '',
    cartographyApi: env.VUE_APP_CARTOGRAPHY_API_URL || '',
    directoryApi: env.VUE_APP_DIRECTORY_API_URL || '',
    eventStreamApi: env.VUE_APP_EVENT_STREAM_API_URL || '',
    offerApi: env.VUE_APP_OFFER_API_URL || '',
    searchApi: env.VUE_APP_SEARCH_API_URL || '',
    vehicleApi: env.VUE_APP_VEHICLE_API_URL || '',
    adminApi: env.VUE_APP_ADMIN_API_URL || '',
    customerLogo: env.VUE_APP_CUSTOMER_LOGO_URL || '',
    scoringStylesheet: env.VUE_APP_SCORING_STYLESHEET_URL || '',
    flowMapJs: env.VUE_APP_FLOW_MAP_JS_URL || '',
    flowMapEsmJs: env.VUE_APP_FLOW_MAP_ESM_JS_URL || '',
    warehousesMapJs: env.VUE_APP_WAREHOUSE_MAP_JS_URL || '',
    warehousesMapEsmJs: env.VUE_APP_WAREHOUSE_MAP_ESM_JS_URL || '',
    s3pwebTraceabilityPass: env.VUE_APP_S3PWEB_TRACEABILITY_PASS_URL || '',
    s3pwebTraceabilityPassIcon: env.VUE_APP_S3PWEB_TRACEABILITY_PASS_ICON_URL || '',
  },
};
