import { ref } from 'vue';

import { getExchanges } from '@/shared/api/search';
import { AbortOptions } from '@/shared/dataProviders/common';
import { RefreshOptions } from '@/shared/enums/types';

const exchanges = ref<Record<string, string>>();

export async function list(options?: AbortOptions & RefreshOptions): Promise<Record<string, string>> {
  if (!exchanges.value || options?.refresh) {
    const values: Record<string, string> = {};

    for (const item of await getExchanges(options)) {
      values[item.key] = item.value;
    }

    exchanges.value = values;
  }

  return exchanges.value;
}
